import { createGlobalStyle } from 'styled-components';
import { colors } from './colors';

const GlobalStyles = createGlobalStyle`

    body {
        background-color: ${colors.streetEasyGreen};
        color: ${colors.streetEasyWhite}
    }
`

export default GlobalStyles;
