export const colors = {
    colorBlack: '#222',
    colorWhite: '#fff',
    colorAccent: '#f43',
    colorMainBg: '#f1f1f1',
    colorSecondary: '#333',
    colorMeta: '#f6f6f7',
    
    streetEasyWhite: '#dee4e8',
    streetEasyBlack: '#1a1a1a',
    streetEasyGreen: '#125052',
    streetEasyYellow: '#ffe766',
    streetEasyPink: '#e1beb0',
    streetEasyWhiteOpacity: 'rgba(222,228,232, 0.25)',
    
    gradientBg: 'linear-gradient(120deg, #c3dfdd 0%, #f7dcca 100%)',
    gradientBgInvert: 'linear-gradient(120deg, #f7dcca  0%, #c3dfdd 100%)',
  };
  